.catalog-item-size
{
    cursor:pointer;
}

.order-form-card
{
    max-width: 30rem; margin: 0 auto;
}

